import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IAPI } from '../interfaces/api.interface';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private _headers : HttpHeaders = new HttpHeaders({
    'Content-Type' : 'application/json'
  }) 


  constructor(private http : HttpClient) { }


  post (request : IAPI) : Observable<any>{
    const body = typeof request.body !== 'string' ? request.body : JSON.stringify(request.body);    
    const { url, headers, fullError } = this.prepareRequest(request);

    return this.http.post(url, body, {headers : headers}).pipe(
      catchError((error)=> throwError(() => fullError? error : error.error))
    );
  }

  put (request : IAPI) : Observable<any>{
    const body = typeof request.body !== 'string' ? request.body : JSON.stringify(request.body);    
    const { url, headers, fullError } = this.prepareRequest(request);

    return this.http.put(url, body, {headers : headers}).pipe(
      catchError((error)=> throwError(() => fullError? error : error.error))
    );
  }

  delete (request : IAPI) : Observable<any>{ 
    const { url, headers, fullError } = this.prepareRequest(request);

    return this.http.delete(url, {headers : headers}).pipe(
      catchError((error)=> throwError(() => fullError? error : error.error))
    );
  }

  get ( request : IAPI) : Observable<any>{
    const { url, headers, fullError } = this.prepareRequest(request);

    return this.http.get(url, {headers : headers}).pipe(
      catchError((error)=> throwError(() => fullError? error : error.error))
    );
  }




  private prepareRequest(request: IAPI): { 
    url: string, 
    headers: HttpHeaders, 
    fullError: boolean 
  } {
    const url: string = request.url ?? environment.url;
    let headers: HttpHeaders = request.headers ?? this._headers;
    const fullError: boolean = request.fullError ?? false;
    const method : string = request.method;

    if(request.auth) {
      headers = request.auth.startsWith('Token')
        ? headers.set('Authorization', `${request.auth}`)
        : headers.set('Authorization', `Token ${request.auth}`);
    }
  
    return {
      url: url + method,
      headers,
      fullError
    };
  }


}
